import "./App.css";
import Confetti from "./Confetti";

const App = () => {
  return (
    <div className="App">
      <Confetti />
      <header className="App-header">
        <img className="logo" src="tree.png" alt="Christmas tree logo"></img>
        <h1 style={{ marginBottom: "0px" }}>Trio<span className="red">boom</span></h1>
        <p className="red" style={{ marginTop: "10px", marginBottom: "50px" }}>
          Build a Better Christmas
        </p>
      </header>
    </div>
  );
};

export default App;
